import React from 'react'
import Layout from '../components/layout'

export default function Page404({ location }) {
  return (
    <Layout location={location}>
      <h1 className="my-5 text-lg text-red-800 uppercase border-b border-solid border-one-500 pb-2">
        Esta página não existe!
      </h1>
    </Layout>
  )
}
